<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Record'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-row>
                  <b-col>
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="date_label"
                      label="Date :"
                      label-for="date"
                    >
                      <b-form-input
                        id="date"
                        v-model="form.date"
                        type="date"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="pabrik_id_label"
                      label="Pabrik :"
                      label-for="pabrik_id"
                    >
                      <b-form-select
                        size="sm"
                        id="pabrik"
                        v-model="form.pabrik_id"
                        :options="pabrik"
                        @change="choosePabrik"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="plant_id_label"
                      label="Plant :"
                      label-for="plant_id"
                    >
                      <b-form-select
                        id="plant_id"
                        v-model="form.plant_id"
                        :options="plant"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="equipment_id_label"
                      label="Equipment :"
                      label-for="equipment_id"
                    >
                      <vue-suggestion
                        id="equipment_id"
                        :items="equipment"
                        v-model="ItemEquipment"
                        :setLabel="setLabel"
                        :itemTemplate="itemTemplate"
                        @changed="inputChangeEquipment"
                        @selected="chooseEquipment"
                        inputClasses="form-control"
                        placeholder="Enter Text..."
                        size="sm"
                      >
                      </vue-suggestion>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="part_label"
                      label="Part :"
                      label-for="part_id"
                    >
                      <vue-suggestion
                        id="part_id"
                        :items="part"
                        v-model="item"
                        :setLabel="setLabel"
                        :itemTemplate="itemTemplate"
                        @changed="inputChangePart"
                        @selected="itemSelectedPart"
                        inputClasses="form-control"
                        placeholder="Enter Text..."
                        aria-required="true"
                      >
                      </vue-suggestion>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="img_label"
                      label="File :"
                      label-for="img"
                    >
                      <b-form-file
                        id="img"
                        v-model="form.img"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="report_no_label"
                      label="Nomor Laporan :"
                      label-for="report_no"
                      description="Jika kosong akan terisi otomatis dari sistem."
                    >
                      <b-form-input
                        id="report_no"
                        v-model="form.report_no"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="jenis_record_label"
                      label="Jenis Record :"
                      label-for="jenis_record"
                    >
                      <b-form-select
                        id="jenis_record"
                        v-model="form.jenis_record"
                        :options="jenis_record"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="description_label"
                      label="Deskripsi :"
                      label-for="description"
                    >
                      <vue-editor id="description" v-model="form.description"></vue-editor>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/report/index')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-modal ref="error-modal" title="Peringatan!">
            <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
              <br />
              <span v-for="(val, index) in errors" :key="index">
                <ul>
                  <li v-for="(arr, i) in val" :key="i">
                    {{ arr }}
                  </li>
                </ul>
                <br />
              </span>
            </b-alert>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import { mapState } from "vuex";

import ApiService from '@/core/services/api.service'
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

import { VueEditor } from 'vue2-editor'
import $ from 'jquery'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  // mixins: [validationMixin],
  name: 'addequipment',
  data() {
    return {
      form: {
        date: null,
        report_no: null,
        pabrik_id: null,
        plant_id: null,
        pabrik: '',
        plant: '',
        equipment_id: null,
        part_id: null,
        img: null,
        jenis_record: null,
        description: null,
      },
      equipment: [],
      pabrik: [],
      plant: [],
      part: [],
      jenis_record: [
        { value: null, text: '-- Pilih Jenis Record --' },
        { value: 'Laporan rekomendasi', text: 'Laporan rekomendasi' },
        { value: 'Failure analysis', text: 'Failure analysis' },
        { value: 'Laporan evaluasi', text: 'Laporan evaluasi' },
        { value: 'Foto ', text: 'Foto ' },
        { value: 'Video', text: 'Video' },
        { value: 'Lainnya', text: 'Lainnya' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      ItemEquipment: null,
      ItemPlant: null,
      item: {},
      itemTemplate,

      show: true,
      loading: false,

      errors: [],
      showDismissibleAlert: false,
    }
  },
  components: {
    KTCodePreview,
    VueEditor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Report', route: '/report/index' },
      { title: 'Create Record' },
    ])

    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()

    this.form.date = yyyy + '-' + mm + '-' + dd

    var self = this

    ApiService.setHeader()

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    onSubmit() {
      // event.preventDefault()
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      var self = this
      self.loading = true

      const date = self.form.date
      const report_no = self.form.report_no
      const pabrik_id = self.form.pabrik_id
      const plant_id = self.form.plant_id
      const equipment_id = self.form.equipment_id
      let part_id = self.form.part_id
      const img = self.form.img
      const jenis_record = self.form.jenis_record
      const description = self.form.description

      if (self.item.name == null) {
        self.errors = { part: ['Part Harus Diisi'] }
        self.showDismissibleAlert = true
        self.loading = false
        self.$refs['error-modal'].show()
        return
      }

      if (part_id == null) {
        part_id = self.item.name
      }

      const dataku = new FormData()

      dataku.append('date', date)
      if (report_no) dataku.append('no_report', report_no)
      // dataku.append('section', section);
      if (pabrik_id) dataku.append('pabrik_id', pabrik_id)
      if (plant_id) dataku.append('plant_id', plant_id)
      if (equipment_id) dataku.append('equipment_id', equipment_id)
      dataku.append('part_id', part_id)
      dataku.append('jenis_record', jenis_record)
      // dataku.append("inspector", inspector);
      dataku.append('description', description)
      dataku.append('report_type', 'record')
      if (img) {
        dataku.append('img', img)
      }

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/report/create/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/report/index')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
            self.$refs['error-modal'].show()
          }
        },
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      if (this.form.plant_id && text) {
        url += '/' + this.form.plant_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    inputChangePlant(text) {
      // your search method
      var self = this
      var url = '/master/plant/showplant/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data

            self.plant.splice(0, self.plant.length)
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                id: plant[i].id_plant,
                name: plant[i].nama_plant,
                pabrik: plant[i].nama_pabrik,
                pabrik_id: plant[i].pabrik_id,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
      this.form.pabrik_id = value.pabrik_id
      this.form.plant_id = value.plant_id
      this.form.pabrik = value.pabrik
      this.form.plant = value.plant

      this.ItemPlant = {
        id: value.plant_id,
        name: value.plant,
        pabrik: value.pabrik,
        pabrik_id: value.pabrik_id,
      }
    },
    choosePlant: function(value) {
      this.form.plant_id = value.id
      this.form.plant = value.nama_plant
      this.form.pabrik_id = value.pabrik_id
    },
    async choosePabrik() {
      var self = this
      await ApiService.get(
        '/master/plant/all?pabrik_id=' + self.form.pabrik_id + '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plant --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    itemSelectedPart(item) {
      this.form.part_id = item.id
    },
    inputChangePart(text) {
      // your search method
      var self = this
      self.item.name = text
      ApiService.get('/master/part?page=1&nama_part=' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var part = data.data.data
            self.part.splice(0, self.part.length)

            for (let i = 0; i < part.length; i++) {
              self.part.push({
                id: part[i].id_part,
                name: part[i].nama_part,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      // this.modalForm.part = text;
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
